<template>
  <div class="detal-info">
    <el-table
      v-loading="isLoading"
      border
      :show-header="false"
      :lazy="true"
      :data="FORMATED_TIRE_MODEL"
      style="width: 100%">
      <el-table-column
        label="Поле"
        prop="label" />
      <el-table-column
        label="Значение"
        prop="value">
        <template slot-scope="scope">
          {{ isBoolean(scope.row.value) ? has(scope.row.value) : scope.row.value }}
        </template>
      </el-table-column>
    </el-table>
    <div
      v-if="false"
      class="title-table">
      Спецификации
    </div>
    <el-table
      v-if="false"
      border
      :show-header="false"
      :lazy="true"
      :data="tireModelsSpecifications"
      style="width: 100%">
      <el-table-column
        label="Поле"
        prop="label" />
      <el-table-column
        label="Значение"
        prop="value">
        <template slot-scope="scope">
          {{ isBoolean(scope.row.value) ? hasString(scope.row.value) : scope.row.value }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
// eslint-disable-next-line import/no-unresolved
import { hasString, isBoolean } from '@/utils';

import { dataTableSpecifications } from './data.js';

export default {
  name: 'DetalInfo',
  data() {
    return {
      tireModelsSpecifications: dataTableSpecifications,
    };
  },
  computed: {
    ...mapGetters(['FORMATED_TIRE_MODEL', 'STRUCTURE_REQUEST_STATUS', 'FORMATED_TIRE_MODEL_REQUEST_STATUS']),
    isLoading() {
      return this.FORMATED_TIRE_MODEL_REQUEST_STATUS === 'requested';
    },
  },
  methods: {
    hasString,
    isBoolean,
  },
};
</script>
<style lang="scss">
  .detal-info {
    .title-table {
      text-align: left;
      margin: 40px 0 20px 0;
    }
  }
</style>
