export const olddataTable = [
  {
    key: 'id',
    label: 'ID',
    value: '213123',
  },
  {
    key: 'bx_id',
    label: 'Битрикс ID',
    value: '768678',
  },
  {
    key: 'name',
    label: 'Название EN',
    value: 'Blizzak DM-V3',
  },
  {
    key: 'title',
    label: 'Название RU',
    value: 'Близзак ДМ XD',
  },
  {
    key: 'slug',
    label: 'URL',
    value: '/blizzak-dm-v3',
  },
  {
    key: 'season',
    label: 'Сезон',
    value: 'Зимние',
  },
  {
    key: 'transport_model_type_id',
    label: 'Тип Модели транспорта',
    value: 'Легковой',
  },
  {
    key: 'tire_model_type_manufacturer_id',
    label: 'Производитель Модели шины',
    value: 'Bridgestone',
  },
  {
    key: 'tire_model_brand_id',
    label: 'Категория бренда шины',
    value: '213123',
  },
  {
    key: 'is_active',
    label: 'Активность',
    value: '213123',
  },
  // {
  //   key: 'timespams',
  //   label: 'Активность',
  //   value: '10',
  // },
];

export const dataTable = [
  {
    key: 'id',
    label: 'ID',
    value: '213123',
  },
  {
    key: 'id',
    label: 'ID',
    value: '213123',
  },
];

export const dataTableSpecifications = [
  {
    key: 'is_studded',
    label: 'Шипованная/Нешипованная',
    value: 'Шипованная',
  },
  {
    key: 'has_runflat',
    label: 'RFT, Технология Runflat',
    value: false,
  },
  {
    key: 'has_tube_type',
    label: 'TT, TubeType - камерный тип',
    value: false,
  },
  {
    key: 'has_seal',
    label: 'Антипрокольная система(Seal)',
    value: false,
  },
  {
    key: 'is_ece',
    label: 'Шина соответствует европейским требованиям ECE',
    value: true,
  },
  {
    key: 'has_tubeless',
    label: 'TL, Tubeless - "Не имеющий камеры"',
    value: true,
  },
  {
    key: 'has_c',
    label: 'Технология С',
    value: true,
  },
  {
    key: 'has_p',
    label: 'Технология P',
    value: false,
  },
];
